import React from 'react';
import './app.css';


class App extends React.Component {
  render() {
    return (
      <div className="main">
        <h1>Welcome to 8828 Consulting</h1>
        <p>The page is currently in development, but if you want help with a project idea/website we'd love to be your go-to!</p>
        <p>Contact us at <a href="mailto:8828consulting@gmail.com">8828consulting@gmail.com</a>!</p>
      </div>
    )
  }
}

export default App;
